
.preview-tooltip {
  display: flex;
  flex-direction: column;
  color: var(--icon-default);
  width: 100%;
  &__description {
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
  }
  &__info-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 10px;
    background: linear-gradient(
      112.2deg,
      rgba(187, 188, 199, 0.4) -46.39%,
      rgba(187, 188, 199, 0) 112.38%
    );
    border-radius: 10px;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
  }
  &__info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 7px;
    margin-bottom: 7px;
    border-bottom: 1px solid var(--grey-light);

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  &__activity-amount-tag {
    font-weight: 450;
    font-size: 10px;
    line-height: 14px;

    color: #fff;
    padding: 2px 10px;
    background: var(--lilac-dark);
    border-radius: 6px;

    margin-left: 3px;
    &:first-child {
      margin-left: 20px;
    }
  }
  &__text-capitalize {
    text-transform: capitalize;
  }
  &__button-container {
    align-self: flex-end;
  }
}


.link {
  display: flex;
  button {
    border: none;
    box-shadow: none;
  }
  &__img {
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;
    width: 190px;
    border-radius: 10px 0px 0px 10px;
    background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    cursor: pointer;

    svg {
      path {
        fill: #fff;
      }
    }
  }
  &__content {
    display: flex;
    flex: 1;
    padding: 15.5px 20px 25px 20px;
    border-radius: 0px 10px 10px 0px;
    background: #fff;
  }
  &__main-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__url {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-word;
    overflow: hidden;
    text-transform: uppercase;
    color: var(--grey-dark);
    width: 100%;

    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
  }
  &__name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-word;
    overflow: hidden;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: var(--black-dark);
    border-bottom: 1px solid transparent;

    &--editing {
      color: var(--icon-default);
      border-bottom-color: #aaa;
    }
  }
  &__description,
  &__description-placeholder {
    margin-top: 5px !important;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
  }
  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-word;
    overflow: hidden;
    box-sizing: border-box;
    color: var(--grey-blue-light);
    border-bottom: 1px solid transparent;

    &--editing {
      text-overflow: clip;
      width: 100%;
      border-bottom-color: #aaa;
    }
  }
}

.day-tooltip {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  width: 160px;
  background-color: var(--light-gray-darker);
  color: var(--white);
  text-align: center;
  padding: 8px 0;
  border-radius: 6px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
}

.day-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--light-gray-darker) transparent transparent transparent;
}

.day-tooltip-visible {
  visibility: visible;
  opacity: 1;
}

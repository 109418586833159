#doorbell {
  #doorbell-form {
    padding: 30px;
    border-radius: 10px;
    box-shadow: none;
  }

  #doorbell-add-attachments-label-button span img {
    height: 30px !important;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 7px;
  }

  #doorbell-add-attachments-label-button {
    padding-left: 0px, 5px !important;
    height: 40px;
  }

  #doorbell-add-attachments-label-button span {
    height: 100%;
  }

  #doorbell-add-attachments-label-button span {
    padding-left: 40px;
    line-height: 3;
  }

  form legend {
    border: none;
  }

  #doorbell-add-attachments-label-button {
    width: 100% !important;
  }

  #doorbell-form {
    background: #FFFFFF;
    border: none;
  }

  #doorbell-title {
    color: #000000 !important;
  }

  #doorbell-container .close {
    font-weight: 100;
    text-shadow: none;
    color: #000000;
    font-size: 30px;
  }

  #doorbell-add-attachments-label-button {
    width: -webkit-fill-available !important;
  }
  #doorbell-add-attachments-label-button {
    border-color: #777777;
  }

  .form-control {
    background: rgb(242,242,247);
    border-radius: 10px;
    border-color: #f2f2f7;
    color: #000000 !important;
    font-size: 12px;
  }

  #doorbell-submit-button:hover {
    border: 1px solid #D0D2DB!important;
    box-shadow: 0px 4px 20px rgba(18,18,18,0.4)!important;
  }
  #doorbell-submit-button {
    border-radius: 8px;
    margin-top: 20px;
    border: 1px solid #A793F6 !important;
    background: transparent !important;
    color: #000000 !important;
    font-size: 16px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999999;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #999999;
  }

  .form-group input,
  #doorbell-add-attachments-label-button {
    background: rgb(242,242,247);
    border-radius: 10px;
    border-color: #f2f2f7;
    color: #000000 !important;
    font-size: 12px;

  }

  .form-group select {
      background: rgb(242,242,247);
      border-radius: 10px;
      border-color: #f2f2f7;
      color: #000000 !important;
      font-size: 12px;
      height: 48px !important;
  }
  .custom-select:focus {
    box-shadow: 0px 0px 0px 2px #5b4b9b, inset 0px 0px 0px 1px #a793f6 !important;
    background: #222222 !important;
    border-color: #a793f6 !important;
  }
  .form-group button#submit {
    width: 100% !important;
    max-width: none !important;
  }
  .form-group label {
    color: #cccccc !important;
    text-align: left !important;
    width: 100% !important;
    margin-top: 5px !important;
  }

  #doorbell-email {
    min-height: 44px;
  }

  #doorbell-add-attachments-label-button:hover {
    color: #999999 !important;
  }
}

#doorbell-button {
  background: #1A2535;
  color: #FFFFFF;
  right: 0 !important;
  margin-right: 220px;
}

#doorbell-background {
  background: rgb(17 17 17 / 18%);
  backdrop-filter: saturate(180%) blur(20px);
  opacity: 100%;
}
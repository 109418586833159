
.divider {
  background-color: var(--grey-light);
}

.text-muted {
  color: var(--grey-dark);
}

.btn-menu {
  padding: 0.5rem !important;
  min-width: 48px;
  min-height: 48px;
  .btn-menu-icon {
    width: 24px;
    height: 24px;
    color: var(--icon-default);
  }
  &.btn-menu-light {
    &:hover {
      background-color: $gray-100;
    }
    &:active,
    &.active {
      background-color: var(--black-dark);
      .btn-menu-icon {
        color: white;
      }
    }
  }

  &.btn-menu-dark {
    &:hover {
      background-color: $gray-800;
    }
    &:active,
    &:focus {
      background-color: var(--black-darkest);
    }
  }
}

.btn {
  // icon button
  svg {
    width: 22px;
    height: 22px;
  }
  .btn-tiny-icon {
    width: 12px;
    height: 12px;
  }
  &.btn-primary {
    fill: red;
  }
  &.btn-outline {
    box-sizing: border-box !important;
    border: 1px solid currentColor !important;
  }
}

.badge {
  text-transform: uppercase;
  font-weight: normal;
  &.badge-light {
    background: linear-gradient(112deg, var(--grey-light), white) !important;
  }
}

.flex-even {
  flex: 1;
}

.caret-off::before {
  display: none !important;
}

.caret-off::after {
  display: none !important;
}

$tablet: 1024px;
@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
$circle-size: 134px;
$circle-size-desktop: 167px;
$circle-background: #743eb3;
$circle-color: #9f69e1;
$inset-size: 118px;
$inset-size-desktop: 151px;
$inset-color: #34495e;
$transition-delay: 5.5s;

.skill-progress-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;

  &-label {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }

  &-rating {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-percents {
    font-size: 14px;
    line-height: 20px;
  }
}

.sonar-progress-container {
  width: 276px;
  max-width: 276px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9e9ed;
  border-radius: 8px;
  padding: 30px;
  @media (max-width: 450px) {
    padding: 10px;
    width: 100%;
  }
}

.sonar-progress {
  &__tooltip {
    position: relative;
    z-index: 1000;
  }
  &__graph-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    position: absolute;
    z-index: 2;
    top: 25%;
    left: 25%;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    @include tablet {
      width: 76px;
      height: 76px;
    }
    svg {
      width: 60px;
      height: 60px;
      @include tablet {
        width: 76px;
        height: 76px;
      }
    }
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #743eb3;
    position: relative;
    width: 134px;
    height: 134px;
    @include tablet {
      height: 167px;
      width: 167px;
    }
  }
  &__outer-circle {
    position: relative;
    border-radius: 50%;
    background: radial-gradient(circle, #fffffff0, #ffffff);
    width: 118px;
    height: 118px;
    @include tablet {
      height: 151px;
      width: 151px;
    }
  }

  &__green-scanner,
  &__green-scanner--dot {
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    top: -8px;
    left: -8px;
    background: conic-gradient(rgba(187, 133, 255, 0.5) 10%, rgba(187, 133, 255, 0) 25%);
    animation: scan 3s linear;
    animation-fill-mode: forwards;

    width: 134px;
    height: 134px;
    @include tablet {
      height: 167px;
      width: 167px;
    }
    &--dots {
      animation: scan-dots var(--high-dot-sec) linear;
      animation-delay: 3s;
    }
    &--offset {
      transform: rotate(var(--high-dot-turn));
      animation: scan-dots-offset var(--tradar-sec) linear;
      animation-delay: var(--tradar-delay);
    }
    &--total {
      transform: rotate(var(--low-dot-turn));
      animation: scan-dots-total 0.1s linear;
      animation-delay: var(--tradar-delay);
      animation-fill-mode: forwards;
      background: conic-gradient(
        rgba(187, 133, 255, 0.5) var(--low-dot-perc),
        rgba(187, 133, 255, 0) var(--high-dot-perc)
      );
    }
  }

  &__outer-circle:after {
    animation-delay: 1s;
  }

  &__dot-container {
    position: absolute;
    height: 116px;
    left: calc(50%);
    top: 1px;
    transform: translateX(-50%);
    z-index: 200;
    @include tablet {
      height: 149px;
    }
    &--low {
      z-index: 300;
    }
    &--avg {
      z-index: 150;
      top: 1px;
      @include tablet {
        height: 150px;
        top: 1px;
      }
    }
  }
  &__dot-wrapper {
    position: relative;
    z-index: 100;
    height: 100%;
    opacity: 0;
    &--low {
      animation: low-dot var(--low-dot-sec) linear;
      animation-delay: 3s;
      animation-fill-mode: forwards;
    }
    &--high {
      animation: high-dot var(--high-dot-sec) linear;
      animation-delay: 3s;
      animation-fill-mode: forwards;
    }
    &--avg {
      animation: avg-dot var(--avg-dot-sec) ease-out 6s forwards;
      .sonar-progress__dot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border: 5px solid #bb85ff91;
        top: -15px;
        @include tablet {
          top: -14px;
        }
        &::before {
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          background: #b37ef4;
          border-radius: 50%;
        }
      }
    }
  }
  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: auto;
    position: relative;
    top: -9px;
    z-index: 300;
    &--low {
      z-index: 2000;
    }
  }

  &-circle {
    margin: 20px auto;
    width: $circle-size;
    height: $circle-size;
    position: absolute;
    border-radius: 50%;
    @include tablet {
      width: $circle-size-desktop;
      height: $circle-size-desktop;
    }
    .sonar-progress-circle__slice,
    .sonar-progress-circle__fill {
      width: $circle-size;
      height: $circle-size;
      position: absolute;
      backface-visibility: hidden;
      transition: transform var(--avg-dot-sec) ease-out $transition-delay;
      border-radius: 50%;
      @include tablet {
        width: $circle-size-desktop;
        height: $circle-size-desktop;
      }
    }
    .sonar-progress-circle__slice {
      clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
      .sonar-progress-circle__fill {
        clip: rect(0px, $circle-size/2, $circle-size, 0px);
        background-color: $circle-color;
      }
      @include tablet {
        clip: rect(0px, $circle-size-desktop, $circle-size-desktop, $circle-size-desktop/2);
        .sonar-progress-circle__fill {
          clip: rect(0px, $circle-size-desktop/2, $circle-size-desktop, 0px);
          background-color: $circle-size-desktop;
        }
      }
    }
    .sonar-progress-circle__overlay {
      width: $inset-size;
      height: $inset-size;
      position: absolute;
      margin-left: ($circle-size - $inset-size)/2;
      margin-top: ($circle-size - $inset-size)/2;
      background-color: $inset-color;
      border-radius: 50%;
      @include tablet {
        margin-left: ($circle-size-desktop - $inset-size-desktop)/2;
        margin-top: ($circle-size-desktop - $inset-size-desktop)/2;
        width: $inset-size-desktop;
        height: $inset-size-desktop;
      }
    }

    $i: 0;
    $increment: 180deg / 100;
    @while $i <= 100 {
      &[data-progress='#{$i}'] {
        .sonar-progress-circle__slice.full,
        .sonar-progress-circle__fill {
          transform: rotate($increment * $i);
        }
        .sonar-progress-circle__fill.sonar-progress-circle__bar {
          transform: rotate($increment * $i * 2);
        }
        $i: $i + 1;
      }
    }
  }

  @keyframes high-dot {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
      transform: rotate(var(--high-dot-turn));
    }
  }
  @keyframes low-dot {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
      transform: rotate(var(--low-dot-turn));
    }
  }
  @keyframes avg-dot {
    from {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: rotate(var(--avg-dot-turn));
    }
  }
  @keyframes scan {
    from {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: rotate(1turn);
    }
  }

  @keyframes scan-dots {
    from {
      opacity: 1;
      transform: rotate(0);
    }
    85% {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: rotate(var(--high-dot-turn));
    }
  }

  @keyframes scan-dots-offset {
    from {
      opacity: 0;
      transform: rotate(var(--high-dot-turn));
    }
    20% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: rotate(359deg);
    }
  }

  @keyframes avg-dot-progress {
    from {
      transform: rotate(0deg);
      opacity: 1;
    }
    to {
      opacity: 1;
      transform: rotate(var(--avg-dot-turn));
    }
  }

  @keyframes avg-dot-progress-more {
    from {
      transform: rotate(0deg);
      opacity: 1;
      border-color: #9f69e1;
    }
    to {
      opacity: 1;
      border-color: #9f69e1;

      transform: rotate(180deg);
    }
  }
  @keyframes scan-dots-total {
    from {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    to {
      opacity: 1;
      background: conic-gradient(
        rgba(187, 133, 255, 0.5) 0%,
        rgba(187, 133, 255, 0) var(--diff-perc)
      );
    }
  }

  @keyframes ripple {
    to {
      transform: scale(2.5);
    }
  }
}

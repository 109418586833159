//
//  Tooltip
//

// Base
.tooltip {
  .tooltip-inner {
    box-shadow: $tooltip-box-shadow;
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }

  // Dark Theme
  &.tooltip-dark {
    @include tooltip-theme($dark, $dark-inverse, $dark);
  }
}

.ant-tooltip-content {
  .ant-tooltip-inner {
    border-radius: 4px;
    background: #57575C;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
    width: max-content;
    max-width: 250px;
  }
}

.ant-tooltip-placement-left {
  .ant-tooltip-arrow {
    transform: translate(60%, -50%) !important;
  }
}
@import '../../../../assets/sass/init';

.header-toolbar {
  display: flex;
  .btn-menu {
    margin-right: 0.5rem;
  }
  .btn-avatar {
    border-radius: 50%;
    padding: 0 !important;
    width: 45px;
    height: 45px;
    transition: opacity 250ms;
    &:active,
    &:focus {
      .avatar-bg {
        opacity: 0;
      }
    }
  }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.chart-data-selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;

  .ant-checkbox-wrapper {
    &:first-child {
      .ant-checkbox {
        display: none;
      }
    }
  }

  span {
    font-weight: 450 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #57575c !important;
  }
  &--no-checkbox {
    gap: 25px;
  }
  &__option {
    &-overall,
    &-labs,
    &-standard {
      &:after {
        content: '\A';
        width: 7px !important;
        height: 7px;
        border-radius: 50%;
        background: #a793f6;
        display: inline-block;
      }
    }
    &-started,
    &-total,
    &-videos {
      &:after {
        content: '\A';
        width: 7px !important;
        height: 7px;
        border-radius: 50%;
        background: #3e6496;
        display: inline-block;
      }
    }
    &-finished {
      &:after {
        content: '\A';
        width: 7px !important;
        height: 7px;
        border-radius: 50%;
        background: #4a857f;
        display: inline-block;
      }
    }
    &-passed,
    &-skill_dive,
    &-quizzes {
      &:after {
        content: '\A';
        width: 7px !important;
        height: 7px;
        border-radius: 50%;
        background: #f19e5b;
        display: inline-block;
      }
    }
    &-activities-overall {
      &:after {
        background: #fd8897;
      }
    }
  }
  &__no-checkbox-option {
    &:before {
      content: '\A';
      width: 7px !important;
      height: 7px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 7px;
    }
    &-overall {
      &:before {
        background: #fd8897;
      }
    }
    &-labs,
    &-standard,
    &-licensed {
      &:before {
        background: #a793f6;
      }
    }
    &-started,
    &-total,
    &-videos {
      &:before {
        background: #3e6496;
      }
    }
    &-finished {
      &:after {
        background: #4a857f;
      }
    }
    &-passed,
    &-skill_dive,
    &-quizzes {
      &:before {
        background: #f19e5b;
      }
    }
  }
}

.desktop-only {
  display: none;

  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.no-mobile {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.mobile-only {
  display: flex;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.tablet-only {
  display: flex;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.chart-data-selector {
  &.desktop-only {
    label.ant-checkbox-wrapper .ant-checkbox {
      display: flex;
    }
  }
  label.ant-checkbox-wrapper.hide .ant-checkbox {
    display: none;
  }
}

.chart-data-selector :first-child {
  label.ant-checkbox-wrapper .ant-checkbox {
    display: none;
  }
}

.activities-chart {
  &__options-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title{
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      padding-left: 20px;
    }
    &-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;
      padding: 0;
      height: 36px;
      &-mob {
        justify-content: space-around;
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}

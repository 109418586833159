@import '../../../assets/sass/init';

.aside-bar {
  background-color: var(--black-dark);
  min-height: 70px;
  z-index: 998;
  .icon-text {
    color: var(--grey-medium);
  }
  .btn-aside {
    svg {
      color: var(--icon-aside-mobile);
      opacity: 0.8;
    }
  }
  .link-active {
    background-color: var(--black-darkest);
    svg {
      opacity: 1;
    }
  }
  &__logo {
    background-color: var(--black-darkest);
    height: 64px;
  }

  &__business-btn {
    border-radius: 50% !important;
    .avatar {
      height: 36px;
      width: 36px;
    }
  }
}

@include media-breakpoint-up(md) {
  .aside-bar {
    position: sticky !important;
    top: 0;
    bottom: 0;

    .btn-aside {
      svg {
        color: var(--icon-aside);
      }
      span {
        color: var(--grey-medium);
      }
    }

    .link-active {
      span {
        color: white;
      }
    }
    &__business-btn {
      transform: translateY(-1rem) scale(1.3);
    }
  }
}

@import '../../assets/sass/init';

.layout {
  &__aside-container {
    //height: calc(100vh - 70px);
    min-height: 15vh;
  }
  @include media-breakpoint-up(md) {
    &__aside-container {
      height: 100vh;
      width: 10% !important;
      // z-index: $zindex-fixed;
      > * {
        height: 100%;
      }
    }
    &__main-container {
      width: 90% !important;
      right: 0;
      &--header {
        width: 90% !important;
        right: 0;
        // z-index: $zindex-fixed;
      }
      &--page-content {
        z-index: 1;
      }
    }
  }
}

:root {
  --fc-border-color: #e8e9f1;
}

.fc .fc-media-screen {
  min-width: 370px;
}
.fc th,
th.fc-col-header-cell.fc-day,
.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-bottom: none;
}

.fc-col-header {
  background: var(--white);
  .fc-col-header-cell a {
    font-size: 12px;
    line-height: 14px;
    color: var(--cert-gray);
    opacity: 0.5;
    font-weight: 450;
    padding: 4px 0 6px;
  }
}

.fc .fc-scroller-liquid-absolute {
  overflow: hidden !important;
}
.fc-scroller-harness.fc-scroller-harness-liquid {
  border-bottom: 1px solid var(--fc-border-color);
}
.fc-scrollgrid-sync-table,
.fc-col-header .fc-theme-standard th {
  border: 1px solid var(--fc-border-color);
}
.no-borders {
  background: var(--ghost-white);

  tbody,
  .fc-scrollgrid-sync-table,
  .fc-col-header .fc-theme-standard th {
    border-color: var(--ghost-white);
  }
  tr {
    border-top: 8px solid var(--ghost-white);
    .fc-daygrid-body td {
      background: var(--white);
    }
  }
  .fc-rax-interactable .fc .fc-daygrid-day.fc-day-today,
  .fc-rax-interactable .fc .fc-daygrid-day.fc-day.fc-day-past {
    background-color: #f7f7fa;
    color: var(--cert-gray);
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--white);
}

.fc .fc-scrollgrid table {
  border-left-style: initial;
  border-top-style: initial;
}

.fc .fc-highlight {
  background: var(--melrose-quartz-80);
  border-radius: 6px;
  border: 2px solid var(--secondary-promo-purple);
}

.fc-daygrid-day-number {
  color: var(--black);
}

.fc-scrollgrid-sync-inner a {
  color: var(--cert-gray-50);
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top {
  .fc-highlight {
    background: var(--melrose-quartz-80);
    border-radius: 6px;
    border: 2px solid var(--secondary-promo-purple);
  }

  .fc-daygrid-day-number {
    font-size: 12px;
    line-height: 14px;
    background: var(--grey-day);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    margin: 5px 0;
  }
}

.fc .fc-daygrid-day-top {
  justify-content: center;
}

.fc .fc-button-primary {
  background-color: var(--fc-button-bg-color);
  border-color: var(--fc-button-border-color);
  color: var(--fc-button-text-color);
}

.fc .fc-button-primary,
.fc .fc-button-primary:hover,
.fc .fc-button-primary:focus,
.fc .fc-button-primary:active,
.fc .fc-button-primary:focus-visible,
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled):active:focus {
  color: var(--lilac);
  background: none;
  border: none;
  box-shadow: none;
}

.fc .fc-button-primary:active {
  opacity: 0.8;
}

.fc .fc-button-primary:hover {
  opacity: 0.8;
}

.fc-event-title-container {
  height: 16px;
  padding: 4px 10px;
  font-size: 9px;
  font-weight: 700;
  .fc-event-title {
    position: absolute;
    top: 1px;
    padding: 0;
    left: 5px;
  }
}

.fc .fc-daygrid-day.disabled-day {
  background-color: #f7f7fa;
  color: var(--cert-gray);
}

.fc-event.fc-event-start:not(.fc-event-end) .fc-event-title-container::after {
  content: '//';
  position: absolute;
  right: 5px;
  bottom: -2px;
  color: var(--white);
  font-size: 27px;
  line-height: 17px;
  letter-spacing: -1px;
  font-weight: 100;
}

.no-borders td {
  border-color: var(--ghost-white);
}
.fc-rax:not(.fc-rax-interactable) .fc-daygrid-day,
.fc-rax:not(.fc-rax-interactable) .fc-daygrid-day-number,
.fc-event {
  cursor: default !important;
}

@import '../../../../assets/sass/init';

.users-stats {
  background-image: var(--purple-gradient-teams);
  .btn {
    .btn-tiny-icon {
      transform: rotate(180deg);
      transition: transform 250ms;
    }
  }

  .btn.collapsed {
    .btn-tiny-icon {
      transform: rotate(0);
    }
  }

  @include media-breakpoint-up(md) {
    border-radius: $border-radius;
  }
}

.slide-buttons-group {
  &__button {
    background: transparent !important;
    border-radius: 4px !important;
    border: none !important;
    width: 32px !important;
    height: 32px !important;

    svg {
      width: 16px !important;
      height: 16px !important;

      path {
        fill: #fff !important;
      }
    }

    &--active {
      background: #fff !important;

      svg {
        path {
          fill: #a793f6 !important;
        }
      }
    }
  }
}

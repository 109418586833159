@import '../../assets/sass/init';

.users-header-filters {
  .icon-purple {
    color: $primary;
    width: 18px;
    height: 18px;
  }

  .btn-add-user {
    position: fixed;
    bottom: 80px;
    left: 1rem;
    right: 1rem;
    z-index: 99 !important;
    > button {
      width: 100% !important;
    }
  }

  .modal-dialog {
    margin: 0 !important;
  }

  .aside-filters-menu {
    min-height: calc(100vh - 72px);
    padding: 30px 2rem;
    padding-top: 90px;
  }

  @include media-breakpoint-up(md) {
    .btn-add-user {
      position: static;
      bottom: auto;
      left: auto;
      right: auto;
      z-index: auto;
      .btn {
        width: auto;
      }
    }
  }
}

@import '../../../../assets/sass/init';
.entities-table {
  &__empty {
    &_title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
    }
    &_subtitle {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
    }
  }
  &__table {
    &-header {
      display: none !important;
      @include media-breakpoint-up(md) {
        display: grid !important;
      }
    }
    &-body {
      &-row {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.users,
.teams,
.courses {
  &-column0 {
    font-size: 14px;
    line-height: 20px;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &__value {
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__scnd {
      font-weight: 400;
    }
  }
  @for $i from 1 through 8 {
    &-column#{$i} {
      font-weight: 700;
      display: none !important;
      font-size: 14px;
      @include media-breakpoint-up(md) {
        display: flex !important;
        margin-left: 25%;
      }

      @include media-breakpoint-down(lg) {
        margin-left: 15%;
      }

      &__value {
        @include media-breakpoint-up(md) {
          margin-right: 13px;
        }
      }
    }
  }
}

.analytics-table {
  @for $i from 0 through 8 {
    .users-column#{$i},
    .teams-column#{$i},
    .courses-column#{$i} {
      margin-left: 0;
    }
  }
}

.users,
.teams {
  &-column0 {
    &__value {
      padding-left: 15px;
    }
  }
  &-column4 {
    &__value {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.column {
  &-multiline {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 0;
    font-weight: 500;
    div {
      font-weight: 450;
      color: var(--light-gray-darker);
    }
  }
  &-border {
    width: 3px;
    min-width: 3px;
    height: 100%;
  }
}
.users-link {
  .users-column0__value {
    color: var(--black-dark);
  }
  .users-column0__scnd {
    color: var(--light-gray-darker);
  }
}

.card-icon-container {
  width: 132px !important;
  height: 132px !important;
  border-radius: 86px !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  svg {
    width: 100px !important;
    height: 100px !important;
  }
}

.stats-panel {
  &__button {
    &-round {
      border-radius: 50% !important;
    }
  }
}

.entities-chart {
  &__collapse {
    display: flex;
    justify-content: flex-end;
  }
}

.ant-dropdown {
  z-index: 9999 !important;
}

.ant-tag-close-icon {
  color: #55554A;

  svg {
    height: 12px;
    fill: #55554A;
  }
}

.ant-tag {
  margin-right: 0;
  padding: 5px 10px;
}
@import '../../../../assets/sass/init';

.stats-card-group {
  .card {
    .stat-text {
      font-size: 1.5rem;
    }
  }
  @include media-breakpoint-up(md) {
    .card {
      max-width: 168px;
      .stat-text {
        font-size: 54px;
        font-weight: 700 !important;
        line-height: 66px;
      }
      .label-user {
        color: #57575c !important;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

//
// Theme style
//

// Mode
$mode: default;

@font-face {
  font-family: 'Circular-Std';
  src: local('Circular-Black'), url(../../fonts/CircularStd-Black.ttf);
  font-weight: 600;
  unicode-range: U+0000-001F, U+0021-00FF;
}

@font-face {
  font-family: 'Circular-Std';
  src: local('Circular-Bold'), url(../../fonts/CircularStd-Bold.ttf);
  font-weight: 500;
  unicode-range: U+0000-001F, U+0021-00FF;
}

@font-face {
  font-family: 'Circular-Std';
  src: local('Circular-Book'), url(../../fonts/CircularStd-Book.ttf);
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-001F, U+0021-00FF;
}

@font-face {
  font-family: 'Circular-Std';
  src: local('Circular-Medium'), url(../../fonts/CircularStd-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0000-001F, U+0021-00FF;
}

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';


.tabs {
  display: flex;
  flex: 1;
  background: #fff;

  &__tab {
    display: flex;
    justify-content: center;
    padding: 10px;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    border-bottom-color: var(--grey-lighter);
    cursor: pointer;
    &--selected {
      border-bottom-color: var(--lilac);
    }

    &--grow {
      flex: 1;
    }
    &--fill {
      flex: 1;
      cursor: initial;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
  &__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 5px;
    font-weight: 700;
    font-size: 12px;
    border-radius: 50%;
    background: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    color: #fff;
  }
}

@import '../../../../assets/sass/init';

.user-select {
  padding: 0 12px;

  &__user-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding-top: 15px;
    padding-bottom: 15px;

    &-email {
      color: var(--light-gray-darker);
      font-size: 12px;
      font-weight: 450;
      line-height: 14px;
    }

    &-label {
      color: var(--black-dark);
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.user-select__filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 70%;
  &--flat {
    max-width: 215px;
    min-width: 215px;
    height: 42px;
    .ant-select,
    .ant-select-selector {
      height: 42px;
    }
    .ant-select-selection-overflow {
      top: -4px;
    }
  }
}

.user-select__filter-content {
  width: 100%;

  .ant-select-selection-overflow-item:last-child {
    padding-left: 15px;
  }

  .ant-select-selection-overflow-item-suffix {
    padding-left: 0 !important;
  }

  .ant-select-arrow {
    color: var(--icon-default);
  }

  .ant-select-selector {
    padding: 12px 10px !important;
  }

  .ant-select-selection-search {
    margin-inline-start: unset;
  }
}

@include media-breakpoint-up(lg) {
  .user-select__filter-container {
    min-width: 300px;
    &--flat {
      min-width: unset;
    }
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  color: var(--black-dark);
  overflow-x: hidden;
  padding-right: 0 !important;

  // TODO: tobe replaced for a better implementacion
  .ant-picker-dropdown-range {
    padding-top: 0;
    width: 100%;
    z-index: 2000;
    @media (min-width: 992px) {
      width: unset;
    }
    .ant-picker-range-arrow {
      display: none;
    }
    .ant-picker-panel-container {
      border-radius: 10px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      .ant-picker-cell.ant-picker-cell-in-view {
        &:hover {
          border-radius: 50%;
        }
        .ant-picker-cell-inner {
          border-radius: 50%;
        }
        &.ant-picker-cell-today .ant-picker-cell-inner {
          border: 1px solid #d3c9fa;
          &::before {
            border-color: transparent;
          }
        }
        &.ant-picker-cell-selected,
        &.ant-picker-cell-range-start,
        &.ant-picker-cell-range-end {
          .ant-picker-cell-inner {
            background: #a793f5;
          }
        }
        &.ant-picker-cell-selected,
        &.ant-picker-cell-in-range,
        &.ant-picker-cell-range-start,
        &.ant-picker-cell-range-end {
          &::before {
            background: #ddd4fc;
          }
          &:hover {
            .ant-picker-cell-inner::after {
              background: #d3c9fa;
            }
          }
        }
        .ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single),
        .ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single) {
          &::before {
            background: #ddd4fc;
          }
        }
        .ant-picker-cell-range-range-hover {
          border: 1px dashed #d3c9fa;
        }
        &.ant-picker-cell-range-hover-start,
        &.ant-picker-cell-range-hover-end,
        &.ant-picker-cell-range-hover {
          &::after {
            border-top: 1px dashed #d3c9fa;
            border-bottom: 1px dashed #d3c9fa;
          }
          &:first-of-type::after {
            border-left: 1px dashed #d3c9fa;
          }
          &:last-of-type::after {
            border-right: 1px dashed #d3c9fa;
          }
        }
        &.ant-picker-cell-range-hover-start::after {
          border-left: 1px dashed #d3c9fa;
        }
        &.ant-picker-cell-range-hover-end::after {
          border-right: 1px dashed #d3c9fa;
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

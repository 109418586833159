
.content-editable {
  display: flex;
  align-items: center;
  grid-gap: 10px;

  span {
    outline: none;
  }

  &__field {
    margin: 0px;
    outline: none;

    &--editing {
      min-width: 100px;
    }
    &--hide {
      display: none;
    }
  }

  &__placeholder-button {
    &--hide {
      display: none !important;
    }
  }
  &__button-wrapper {
    display: flex;
    grid-gap: 10px;
    color: var(--lilac);
    align-items: center;
    cursor: pointer;

    &--disabled {
      cursor: initial;
      svg {
        path {
          fill: var(--grey-light);
        }
      }
    }
  }
}

.add-content {
  .ant-checkbox-wrapper {
    display: flex;
    justify-content: flex-start !important;
    margin-left: 0 !important;
  }
  &__add-link-wrapper {
    display: flex;
    grid-gap: 10px;
  }
  &__add-link-error-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 10px;

    p {
      margin: 0px;
    }

    svg {
      path {
        stroke: #ff5e5e;
      }
    }
  }
}
